@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;1,400&display=swap);
.popout{
  animation: popout 0.1s linear 1;
}

@keyframes popout{
  50%  {transform: scale(1.5);}
}

@media screen and (max-width: 767px) {   
  #img{
    height: 55%;
  } 
}

@media screen and (min-width: 768px) {      
  #img{      
  	height: 65%;
  } 
} 
html{
  overflow: hidden;
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url(/static/media/bgBanteng.f2da2b75.jpg);
  /* background: url(../img/bgBanteng.jpg); */
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  overflow: auto;
  height: 100%;
  /*background: url(../img/bgBanteng.jpg) no-repeat center center fixed;*/
  /*-webkit-background-size: cover;*/
  /*-moz-background-size: cover;*/
  /*-o-background-size: cover;*/
  /*background-size: cover;*/
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -ms-user-select: none;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#header, #counter{
  font-weight: bold;
  color: white;
  -webkit-text-stroke-color: black;
  font-size: 4.5rem;
}

#header {
  -webkit-text-stroke-width: 2px;
}

#counter {
  -webkit-text-stroke-width: 2.5px;
}
